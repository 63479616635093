import {Center, Heading, SimpleGrid} from '@chakra-ui/react';
import {captureException} from '@sentry/react';
import React, {FC} from 'react';
import {useOutletContext} from 'react-router-dom';

import PickingButton from '@app/components/Shop/Picking/_partial/PickingButton';
import {usePrinterLog} from '@app/hooks/usePrinterLog.ts';
import {usePickingCreateCampaignMutation, usePickingListCampaignQuery} from '@app/store/camalo/pickingApi';
import {useUpdateOrderCount} from '@app/store/camalo/userAccess/UpdateOrderCount';
import {shopContext} from '@app/types/ContextType';
import {PrinterHandler} from '@app/utils/PrinterHandler';
import {findPrinterByType} from '@app/utils/PrinterState';

const PickingCampaigns: FC = () => {
  const {shop, printerList} = useOutletContext<shopContext>();
  const {addLogFromPrinterElement, logs} = usePrinterLog();
  const {updateOrderCount} = useUpdateOrderCount();
  const {data: campaignList, isLoading: campaignListLoading} = usePickingListCampaignQuery(shop.slug!);
  const [triggerPickingCampaign, {isLoading: pickingMonoLoading}] = usePickingCreateCampaignMutation();

  if (campaignListLoading) {
    return null;
  }

  if (campaignList === undefined) {
    return <div>Error</div>;
  }

  const updateMonoCount = async (campaign: string): Promise<void> => {
    try {
      const response = await triggerPickingCampaign({shopSlug: shop.slug!, campaign}).unwrap();

      if (response === null || response === undefined) {
        return;
      }

      const responseOrderCount = campaignList.length - response.orderCountLeft;
      updateOrderCount(shop, responseOrderCount, 'picking campaign');
      addLogFromPrinterElement(response.printElement, 'picking campaign');
      const printer = findPrinterByType(printerList, response.printElement.printer);
      if (printer !== undefined) {
        PrinterHandler.pdf(printer!.originalName!, response.printElement.url!);
      }
    } catch (error) {
      captureException(error);
      console.log(error);
      console.error('createPickingMono -> PickingApi.createMono()');
    }
  };

  if (campaignList.length === 0) {
    return null;
  }

  return (
    <>
      <Heading as="h1">Campagnes</Heading>
      <Center>
        <SimpleGrid columns={3} spacing={10}>
          {campaignList.map(campaign => (
            <PickingButton
              key={campaign.campaign}
              count={campaign.count}
              label={campaign.campaign}
              onClick={() => updateMonoCount(campaign.campaign)}
              isLoading={pickingMonoLoading}
            />
          ))}
        </SimpleGrid>
      </Center>
    </>
  );
};

export default PickingCampaigns;
