import {Button, Spinner} from '@chakra-ui/react';
import {ThemeTypings} from '@chakra-ui/styled-system';
import React, {FC, ReactNode} from 'react';

type ButtonActionProps = {
  name: string | ReactNode;
  color?: ThemeTypings['colorSchemes'];
  isDisabled?: boolean;
  isLoading?: boolean;
  displayBlock?: boolean;
  onClick: () => void;
};

export const ButtonAction: FC<ButtonActionProps> = ({
  name,
  color = 'red',
  isDisabled = false,
  isLoading = false,
  displayBlock = false,
  onClick,
}) => {
  return (
    <Button
      colorScheme={color}
      size="xl"
      disabled={isDisabled}
      onClick={onClick}
      sx={{textTransform: 'uppercase', minWidth: displayBlock ? '100%' : 'auto'}}
    >
      {name}
      {isLoading && <Spinner ml="3" />}
    </Button>
  );
};

export const ButtonActionSkeleton: FC = () => {
  return (
    <Button isLoading size="xl" sx={{minWidth: '100%'}}>
      Loading...
    </Button>
  );
};
