export enum PickingType {
  MONO = 'mono',
  MULTI = 'multi',
  MONO_CUSTOMIZED = 'mono_customized',
  MULTI_CUSTOMIZED = 'multi_customized',
  MONO_GIFT = 'mono_gift',
  MULTI_GIFT = 'multi_gift',
  MONO_CLASSIC = 'mono_classic',
  MULTI_CLASSIC = 'multi_classic',
}
