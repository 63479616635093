import {Grid, Icon} from '@chakra-ui/react';
import {captureException} from '@sentry/react';
import React from 'react';
import {FaGift} from 'react-icons/fa';
import {FaPenRuler} from 'react-icons/fa6';
import {IoMdAlert} from 'react-icons/io';
import {useOutletContext} from 'react-router-dom';

import PickingButton from '@app/components/Shop/Picking/_partial/PickingButton';
import {PickingType} from '@app/components/Shop/Picking/types/PickingType.ts';
import {ButtonActionSkeleton} from '@app/components/atoms/Button/ButtonAction';
import {usePrinterLog} from '@app/hooks/usePrinterLog.ts';
import {
  useLazyPickingCreateMonoQuery,
  useLazyPickingCreateMultiQuery,
  usePickingCountQuery,
} from '@app/store/camalo/pickingApi';
import {shopContext} from '@app/types/ContextType';
import {PrinterHandler} from '@app/utils/PrinterHandler';
import {findPrinterByType} from '@app/utils/PrinterState';

const PickingDefault = () => {
  const {shop, printerList} = useOutletContext<shopContext>();
  const {addLogFromPrinterElement} = usePrinterLog();
  const {data: pickingCount, isLoading: pickingCountLoading, isError, refetch} = usePickingCountQuery(shop.slug!);

  const [triggerPickingMono, {isLoading: pickingMonoLoading}] = useLazyPickingCreateMonoQuery();
  const [triggerPickingMulti, {isLoading: pickingMultiLoading}] = useLazyPickingCreateMultiQuery();

  if (pickingCountLoading) {
    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={12}>
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
        <ButtonActionSkeleton />
      </Grid>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  const updateMonoCount = async (pickingType: PickingType): Promise<void> => {
    try {
      let response;
      switch (pickingType) {
        case PickingType.MONO:
        case PickingType.MONO_CUSTOMIZED:
        case PickingType.MONO_GIFT:
        case PickingType.MONO_CLASSIC:
          response = await triggerPickingMono({shopSlug: shop.slug!, pickingType}).unwrap();
          break;
        case PickingType.MULTI:
        case PickingType.MULTI_CUSTOMIZED:
        case PickingType.MULTI_GIFT:
        case PickingType.MULTI_CLASSIC:
          response = await triggerPickingMulti({shopSlug: shop.slug!, pickingType}).unwrap();
          break;
      }
      if (response === null || response === undefined) {
        return;
      }

      addLogFromPrinterElement(response.printElement, pickingType);
      const printer = findPrinterByType(printerList, response.printElement.printer);
      if (printer !== undefined) {
        PrinterHandler.pdf(printer.originalName, response.printElement.url!);
      }
      refetch();
    } catch (error) {
      captureException(error);
      console.log(error);
      console.error('createPickingMono -> PickingApi.createMono()');
    }
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={12}>
      <PickingButton
        count={pickingCount!.countMono}
        onClick={() => updateMonoCount(PickingType.MONO)}
        label={<>Mono</>}
        isLoading={pickingMonoLoading}
      />
      <PickingButton
        count={pickingCount!.countMulti}
        onClick={() => updateMonoCount(PickingType.MULTI)}
        label={<>Multi</>}
        isLoading={pickingMultiLoading}
      />
      <PickingButton
        count={pickingCount!.countMonoCustomized}
        onClick={() => updateMonoCount(PickingType.MONO_CUSTOMIZED)}
        label={
          <>
            Mono <Icon as={FaPenRuler} mx={3} />
          </>
        }
        isLoading={pickingMonoLoading}
      />
      <PickingButton
        count={pickingCount!.countMultiCustomized}
        onClick={() => updateMonoCount(PickingType.MULTI_CUSTOMIZED)}
        label={
          <>
            Multi <Icon as={FaPenRuler} mx={3} />
          </>
        }
        isLoading={pickingMultiLoading}
      />
      <PickingButton
        count={pickingCount!.countMonoGift}
        onClick={() => updateMonoCount(PickingType.MONO_GIFT)}
        label={
          <>
            Mono <Icon as={FaGift} mx={3} />
          </>
        }
        isLoading={pickingMonoLoading}
      />
      <PickingButton
        count={pickingCount!.countMultiGift}
        onClick={() => updateMonoCount(PickingType.MULTI_GIFT)}
        label={
          <>
            Multi <Icon as={FaGift} mx={3} />
          </>
        }
        isLoading={pickingMultiLoading}
      />
      <PickingButton
        count={pickingCount!.countMonoClassic}
        onClick={() => updateMonoCount(PickingType.MONO_CLASSIC)}
        label={<>Mono Classic</>}
        isLoading={pickingMonoLoading}
      />
      <PickingButton
        count={pickingCount!.countMultiClassic}
        onClick={() => updateMonoCount(PickingType.MULTI_CLASSIC)}
        label={<>Multi Classic</>}
        isLoading={pickingMultiLoading}
      />
    </Grid>
  );
};

export default PickingDefault;
