// @ts-ignore
import qz from 'qz-tray';

import {PrintElement} from '@app/types/PrintElement';
import {PrintLog} from '@app/types/PrintLog';
import {Printer} from '@app/types/Printer';

export class PrintHandler {
  static print(printers: Printer[], elements: PrintElement[], orderReference: string): PrintLog[] {
    const logs: PrintLog[] = [];
    elements.forEach((element: PrintElement) => {
      logs.push({
        date: new Date().toDateString(),
        name: orderReference,
        type: element.title,
        link: element.url ?? '',
      } as PrintLog);
      const printer = PrintHandler.findPrinterByType(printers, element.printer);
      if (!printer) {
        return;
      }
      switch (element.format) {
        case 'pdf':
          PrintHandler.pdf(printer.originalName, element.url!, element.copies, element.bothSides);
          break;
        case 'zpl':
          const data = (element.base64 ? atob(element.data) : element.data).split('\n').map(line => line + '\n');
          PrintHandler.zpl(printer.originalName, data, element.copies);
          break;
      }
    });

    return logs;
  }

  static findPrinterByType = (printers: Printer[], type: string): Printer | undefined => {
    return printers.find((printer: Printer) => printer.type === type);
  };
  static pdf(printer: string, assetUrl: string, copies: number = 1, bothSides: boolean = false): void {
    const options: {copies: number; duplex?: any} = {copies: copies};
    if (bothSides) {
      options.duplex = 'short-edge';
    }
    const config = qz.configs.create(printer, options);
    qz.print(config, [
      {
        type: 'pixel',
        format: 'pdf',
        flavor: 'file',
        data: assetUrl,
      },
    ]);
  }

  static zpl(printer: string, zpl: string[], copies: number = 1): void {
    // @ts-ignore
    const config = qz.configs.create(printer, {copies: copies, language: 'ZPL', altPrinting: true});
    qz.print(config, zpl);
  }
}
