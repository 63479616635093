import React, {FC, ReactElement, ReactNode} from 'react';

import {ButtonAction} from '@app/components/atoms/Button/ButtonAction';

type ButtonActionProps = {
  count: number;
  isLoading: boolean;
  label?: string | ReactNode;
  onClick: () => void;
};

const PickingButton: FC<ButtonActionProps> = ({count, isLoading, onClick, label}): ReactElement => {
  return (
    <ButtonAction
      name={
        <>
          {label} - {count}
        </>
      }
      onClick={onClick}
      isDisabled={count === 0}
      isLoading={isLoading}
    />
  );
};

export default PickingButton;
